<template>
  <div>
    <component
      :is="resolveSectionComponent(section.__typename as SectionComponents)"
      v-for="section in data?.index!.hero"
      :key="section.__typename"
      :data="section"
    />
    <div v-if="Array.isArray(data?.index!.blocks) && data?.index!.blocks.length">
      <component
        :is="resolveSectionComponent(section.__typename as SectionComponents)"
        v-for="section in data.index.blocks"
        :key="section.__typename"
        :data="section"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { toHead } from 'vue-datocms'
import { SectionComponents } from '~/types/components'

const { resolveSectionComponent } = useResolveComponent()

const { locale } = useI18n()
const route = useRoute()

const { data } = await useAsyncGql('index', {
  // @ts-ignore
  locale,
  options: {
    initialCache: false
  }
})

route.params.title = data?.value.index!.title as string

useHead(() => {
  return toHead((data.value as any).index.seo)
})
</script>
